import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Fab } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { useScroll } from '../../../hooks/useScroll'
import styles from './style.module.scss'

const FloatingButton: React.FC = () => {
  const { pathname } = useLocation()
  const { smoothScrollTop } = useScroll()
  const buttonStyle = {
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#2557af'
    }
  }

  if (pathname === '/Maintenance') {
    return null
  }

  return (
    <Fab onClick={smoothScrollTop} size="small" aria-label="return to top" sx={buttonStyle}>
      <KeyboardArrowUpIcon className={styles.up_icon} />
    </Fab>
  )
}

export default FloatingButton
