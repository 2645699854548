export const FOOTER_ITEMS = [
  {
    id: 'portfolio',
    name: '投資実績'
  },
  {
    id: 'support',
    name: 'サポート'
  },
  {
    id: 'about',
    name: '会社情報'
  },
  {
    id: 'contact',
    name: 'お問合せ'
  }
] as const
