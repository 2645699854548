import { Box, Typography } from '@mui/material'
import React from 'react'
import topImagePC from '../../assets/png/topImagePC.png'
import styles from './top.module.scss'

const TopPC: React.FC = () => (
  <section id="top">
    <Box pl={8} display="flex" alignItems="center">
      <Box>
        {/* 企業キャッチフレーズ */}
        <Box mb={5} width="380px">
          <p className={styles.company_catch_phrase}>
            <span>独自性</span>と<span>革新性</span>で
          </p>

          <p className={styles.company_catch_phrase}>
            <span>企業価値</span>を<span>創造</span>
            する
          </p>
        </Box>
        {/* 企業詳細説明 */}
        <Box pl={1} width="325px">
          <Typography className={styles.company_description} fontSize="14px" fontWeight="bold">
            AGキャピタルは“独自性”と“革新性”を有し、IPOを目指す中堅・ベンチャー企業のビジネス・パートナーとして企業価値創造に取り組んでいます。
          </Typography>
        </Box>
      </Box>

      {/* トップページの画像 */}
      <img className={styles.catch_image} src={topImagePC} alt="top_catch" />
    </Box>
  </section>
)

export default TopPC
