export const news = [
  {
    date: '2022/01/24',
    detail: '投資実績に12件の企業を追加しました。'
  },
  {
    date: '2022/01/24',
    detail: 'AGキャピタルの公式ホームページをリニューアルしました。'
  }
] as const
