import { Box } from '@mui/material'
import React from 'react'
import SupportImage from '../../assets/png/support.png'
import SupportBg from '../../assets/png/support_bg.png'
import { useSize } from '../../hooks/useSize'
import styles from './support.module.scss'

const Images: React.FC = () => {
  const { matchTabletSize } = useSize()

  return (
    <Box position="relative" p={matchTabletSize ? 3 : 6}>
      <img className={styles.support} src={SupportImage} alt="support" />
      <img className={styles.support_image__bg} src={SupportBg} alt="support_bg" />
    </Box>
  )
}

export default Images
