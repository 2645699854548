import { Box, Grid, Typography } from '@mui/material'
import chunk from 'lodash/chunk'
import React, { useMemo, useRef, useState } from 'react'
import { Swiper as _Swiper } from 'swiper'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import IPOTag from '../../../assets/svg/icon_ipo.svg'
import MandATag from '../../../assets/svg/icon_m&A.svg'
import NewTag from '../../../assets/svg/icon_new.svg'
import { useSize } from '../../../hooks/useSize'
import ArrowButton from '../../Common/ArrowButton'
import { companies } from './companies'
import styles from './companies.module.scss'
import ListFooter from './ListFooter'

const CATEGORY_ICON = {
  NEW: NewTag,
  MandA: MandATag,
  IPO: IPOTag,
  None: undefined
} as const

const CompanyCards: React.FC = () => {
  const { matchTabletSize } = useSize()
  const [activePage, setActivePage] = useState<number>(1)
  const swiperRef = useRef<_Swiper | null>(null)

  const swiperData = useMemo(() => {
    const { size, className } = (() => {
      if (matchTabletSize) {
        return { size: 8, className: styles.companies_list_swiper__sp }
      }
      return {
        size: 12,
        className: styles.companies_list_swiper__pc
      }
    })()
    const chunked = chunk(companies, size)
    return { companies: chunked, total: chunked.length, size, className }
  }, [matchTabletSize])

  const handleBack = () => {
    swiperRef.current?.slidePrev()
  }
  const handleNext = () => {
    swiperRef.current?.slideNext()
  }

  const swiperSlideStyle = matchTabletSize ? styles.swiper_slide__sp : styles.swiper_slide__pc

  return (
    <Box mt={6} textAlign="center">
      <Box className={!matchTabletSize ? styles.swiper_container : ''}>
        {!matchTabletSize && <ArrowButton onClick={handleBack} fill="rgb(0 79 143) 100%" rotate="0deg" />}
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper
          }}
          onActiveIndexChange={(swiper) => {
            setActivePage(swiper.activeIndex + 1)
          }}
          slidesPerView={1}
          className={swiperData.className}
        >
          {swiperData.companies.map((slideCompanies) => {
            return (
              <SwiperSlide className={swiperSlideStyle}>
                <Grid container alignItems="flex-start" flexWrap="wrap" alignContent="start" maxWidth="md">
                  {slideCompanies.map((company) => {
                    const icon = CATEGORY_ICON[company.category]
                    return (
                      <Grid
                        item
                        key={`logo_${company.name}`}
                        xs={6}
                        md={3}
                        textAlign="center"
                        border="1px solid whitesmoke"
                      >
                        <a className={styles.link_to_company} href={company.url} target="blank">
                          <Box className={styles.company_list_box}>
                            <Box position="relative">
                              {icon && <img className={styles.company_tag} src={icon} alt={company.category} />}
                            </Box>
                            <img className={styles.company_logo_img} src={company.logoUrl} alt={company.name} />
                            <Typography fontSize="13px" pb={1} color="#7a7a7a">
                              {company.name}
                            </Typography>
                          </Box>
                        </a>
                      </Grid>
                    )
                  })}
                </Grid>
              </SwiperSlide>
            )
          })}
        </Swiper>
        {!matchTabletSize && <ArrowButton onClick={handleNext} fill="rgb(0 79 143) 100%" rotate="180deg" />}
      </Box>
      <ListFooter
        handleBack={handleBack}
        handleAhead={handleNext}
        currentPage={activePage}
        totalPage={swiperData.total}
      />
    </Box>
  )
}

export default CompanyCards
