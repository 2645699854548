import React from 'react'
import About from '../About'
import Contact from '../Contact'
import News from '../News'
import Portfolio from '../Portfolio'
import Support from '../Support'
import Top from '../Top'
import GroupProduct from '../GroupProduct'

const Home: React.FC = () => (
  <>
    <Top />
    <Portfolio />
    <Support />
    <News />
    <About />
    <Contact />
    <GroupProduct />
  </>
)

export default Home
