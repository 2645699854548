import { useMediaQuery } from '@mui/material'

export const useSize = () => {
  // スマホ
  const matchMobileSize: boolean = useMediaQuery('(max-width: 599px)')
  // タブレット
  const matchTabletSize: boolean = useMediaQuery('(max-width: 799px)')
  // ノートPC
  const matchLaptopSize: boolean = useMediaQuery('(max-width: 1099px)')
  // デスクトップPC
  const matchDesktopSize: boolean = useMediaQuery('(max-width: 1499px)')

  return {
    matchMobileSize,
    matchTabletSize,
    matchLaptopSize,
    matchDesktopSize
  }
}
