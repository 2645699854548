import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import AboutTopImagePC from '../../assets/png/about_pc.png'
import AboutTopImageSP from '../../assets/png/about_sp.png'
import { useSize } from '../../hooks/useSize'
import TitleAndSubTitle from '../Common/TitleAndSubTitle'
import Access from './Access'
import CompanyInfoTableMobile from './CompanyInfoTableMobile'
import CompanyInfoTablePC from './CompanyInfoTablePC'

const About: React.FC = () => {
  const { matchTabletSize } = useSize()

  const responsive = matchTabletSize ? AboutTopImageSP : AboutTopImagePC
  return (
    <section id="about">
      <img style={{ width: '100%', height: '350px', objectFit: 'cover' }} src={responsive} alt="会社情報" />
      <Box m="auto" textAlign="center">
        <TitleAndSubTitle className="gradient" title="About" subtitle="会社情報" />
      </Box>

      <Container maxWidth="md">
        <Box>
          <Typography pb="17px" variant="h2">
            代表挨拶
          </Typography>
          <Typography variant="body1" pb="50px">
            弊社は1985年設立の国際キャピタルを源流として、累計で600社以上のベンチャー企業投資を行い、
            200社以上に及ぶIPO/M&AのExitに並走して参りました。この実績と経験を活かして、これからも新しい時代を築く起業家へのご支援を継続して参ります。
          </Typography>
        </Box>
        <Typography pb="17px" variant="h2">
          会社概要
        </Typography>
      </Container>

      {/* 会社情報リスト */}
      {matchTabletSize ? <CompanyInfoTableMobile /> : <CompanyInfoTablePC />}

      {/* 会社所在地、アクセスなど */}
      <Access />
    </section>
  )
}

export default About
