import { createTheme } from '@mui/material'

export const theme = createTheme({
  typography: {
    fontFamily: ['Roboto', 'source-han-sans-japanese', 'sans-serif'].join(','),
    body1: {
      fontSize: '16px',
      lineHeight: '1.8'
    },
    body2: {
      fontSize: '14px',
      lineHeight: '1.8'
    },
    subtitle1: {
      fontSize: '0.7em'
    },
    subtitle2: {
      fontSize: '20px',
      fontWeight: 'bold'
    },
    h2: {
      fontSize: '1.2em',
      fontWeight: 'bold'
    },
    h3: {
      fontSize: '0.9em',
      fontWeight: 'bold'
    }
  }
})
