import { Box } from '@mui/material'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSize } from '../../hooks/useSize'
import styles from './header.module.scss'
import MenuBar from './MenuBar'
import MenuBarMobile from './MenuBarMobile'

const Header: React.FC = () => {
  // スマホサイズ判別
  const { matchTabletSize } = useSize()
  const { pathname } = useLocation()

  return (
    <header>
      <Box className={styles.container}>
        <Link to={pathname === '/Maintenance' ? '/Maintenance' : '/'}>
          <img className={styles.logo} alt="logo" src={`${process.env.PUBLIC_URL}/logo_en.svg`} />
        </Link>

        {matchTabletSize ? <MenuBarMobile /> : <MenuBar />}
      </Box>
    </header>
  )
}

export default Header
