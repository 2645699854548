import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { useSize } from '../../hooks/useSize'
import styles from './about.module.scss'

const Access: React.FC = () => {
  const { matchTabletSize } = useSize()
  const accessPaddingBottom = matchTabletSize ? '10px' : '17px'
  return (
    <Container id="access" maxWidth="md">
      <Box pt="50px" pb="100px">
        <Typography pb="20px" variant="h2">
          アクセス
        </Typography>

        {/* ここにGoogle Map Api */}
        <Box pb="27px">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.0897239517294!2d139.74863031524586!3d35.650160980201576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188bb7b37f60c1%3A0x1a7a2511f2eeb3a5!2zQUfjgq3jg6Pjg5Tjgr_jg6vjiLE!5e0!3m2!1sja!2sjp!4v1643246448766!5m2!1sja!2sjp"
            title="ag capital address google map"
            width="100%"
            height="500px"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen={undefined}
            loading="lazy"
          />
        </Box>

        <Grid container>
          <Grid item sm={12} md={6}>
            <Typography variant="h2" pb={accessPaddingBottom}>
              所在地
            </Typography>
            <Typography variant="body1">〒105-0014</Typography>
            <Typography variant="body1">東京都港区芝2-31-19 バンザイビル6F</Typography>
          </Grid>

          <Grid item sm={12} md={6} pt={matchTabletSize ? 2 : 0}>
            <Typography variant="h2" pb={accessPaddingBottom}>
              交通アクセス
            </Typography>
            <ul className={styles.access_list}>
              <li>
                <span>JR山手線・京浜東北線 田町駅下車 徒歩10分</span>
              </li>
              <li>
                <span>都営地下鉄三田線 三田駅下車 徒歩5分</span>
              </li>
              <li>
                <span>都営地下鉄浅草線 三田駅下車 徒歩7分</span>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default Access
