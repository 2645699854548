import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useScroll } from '../../hooks/useScroll'
import { useSize } from '../../hooks/useSize'
import CustomHashLink from '../Common/CustomHashLink'
import FloatingButton from './FloatingButton'
import styles from './footer.module.scss'
import { FOOTER_ITEMS } from './FOOTER_ITEMS'

const Footer: React.FC = () => {
  const { pathname } = useLocation()
  const { matchTabletSize } = useSize()
  const { smoothScrollTop } = useScroll()
  const gridSizing = matchTabletSize ? 4 : 0

  return (
    <>
      <footer>
        <div>
          <Container maxWidth="md">
            <Box p="30px 15px 20px 5px" mb="40px" borderBottom="1px solid #ffffff38" textAlign="center">
              {pathname === '/Maintenance' ? null : (
                <Grid container justifyContent="center" rowSpacing={2}>
                  <Grid item xs={gridSizing}>
                    <Link to="/" onClick={smoothScrollTop} className={styles.text}>
                      ホーム
                    </Link>
                  </Grid>

                  {FOOTER_ITEMS.map((item) => (
                    <Grid item xs={gridSizing} key={item.id}>
                      <CustomHashLink className={styles.text} id={item.id} name={item.name}>
                        {item.name}
                      </CustomHashLink>
                    </Grid>
                  ))}

                  <Grid item xs={gridSizing}>
                    <Link to="PrivacyPolicy" className={styles.text}>
                      プライバシーポリシー
                    </Link>
                  </Grid>
                </Grid>
              )}
            </Box>
            <Box textAlign="center">
              <Link to={pathname === '/Maintenance' ? '/Maintenance' : '/'}>
                <img
                  className={styles.logo}
                  style={{ margin: 'auto' }}
                  alt="logo"
                  src={`${process.env.PUBLIC_URL}/logo_en_white.svg`}
                />
              </Link>
              <Typography pt="22px" pb="70px" variant="body1">
                Copyright(c) AG Capital.All rights reserved.
              </Typography>
            </Box>
          </Container>
          {!matchTabletSize && (
            <Box className={styles.floating_btn}>
              <FloatingButton />
            </Box>
          )}
        </div>
      </footer>
    </>
  )
}

export default Footer
