import { Box, Typography } from '@mui/material'
import React from 'react'
import { useSize } from '../../hooks/useSize'

const Paragraph: React.FC = () => {
  const { matchTabletSize } = useSize()

  return (
    <>
      <Box pt="26px" pr={2.5} pl={matchTabletSize ? 2.5 : 10} textAlign="left">
        <Typography fontSize={matchTabletSize ? '20px' : '26px'} fontWeight="bold" pb={3} lineHeight={1.5}>
          企業のホームドクターとして、
          <br />
          効率的な経営コンサルティングを行います。
        </Typography>
        <Typography variant="body1" pb={2}>
          AGキャピタルは、中堅・ベンチャー企業に成長資金を提供する“インベスター”機能と、ホームドクターとしての“コンサルタント”機能を併せ持つ「企業価値創造型のベンチャーキャピタル」を目指しています。
          ホームドクター制では、同じ投資担当者がビジネスパートナーとしてステージごとに直面する問題解決をサポートします。1991年以降、AGキャピタルは60社以上の上場を支援しています。
          AGキャピタルは、中堅・ベンチャー企業の「ホームドクター」として下記のコンサルティングを行います。
          中堅企業が成長発展し、株式公開を果たすためには、企業経営上の課題や問題点を的確に把握し効率よく解決していく必要があります。AGキャピタルは、こうしたニーズにお応えするためのコンサルティングを行い、ご好評をいただいております。
        </Typography>
        <Box mt={1} pl={2} borderLeft="5px solid whitesmoke">
          <Typography color="#2557af" fontSize="1.1em" fontWeight="bold" pb={1}>
            経営コンサルティング
          </Typography>
          <Typography variant="body1">
            経営計画策定、賃借対照表、損益計算書のチェックやアドバイス、その他経営に関するアドバイスを提供します。また、資本政策の立案および増資などの資金調達に関する指導とアドバイスを行います。
          </Typography>
        </Box>
        <Box pt={3}>
          <Typography fontSize="1.2em" fontWeight="bold" pb={1.5}>
            SDGsに取り組む企業を応援します！
          </Typography>
          <Typography variant="body1">
            AGキャピタルは投資活動を通じて、SDGsに示される社会環境課題解決に真摯に向き合い、よりよい未来を創造するベンチャー企業を応援します。
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default Paragraph
