export const useScroll = () => {
  const smoothScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return { smoothScrollTop }
}
