import { Typography } from '@mui/material'
import React from 'react'
import { ReactComponent as MailerIcon } from '../../assets/svg/icon_mail.svg'
import { useSize } from '../../hooks/useSize'
import styles from './contact.module.scss'

const MailerButton: React.FC = () => {
  const { matchTabletSize } = useSize()
  const responsive = matchTabletSize ? styles.mailer_btn__sp : styles.mailer_btn__pc

  // メーラー起動
  const mailTo = () => {
    window.location.href = 'mailTo:info@agcapital.jp'
  }

  return (
    <button onClick={mailTo} type="button" className={responsive}>
      <span>
        <Typography variant="body2" fontWeight="bold" pt="2px">
          メールで連絡する
        </Typography>

        <MailerIcon className={styles.mailer_icon} />
      </span>
    </button>
  )
}
export default MailerButton
