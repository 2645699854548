import { Box, Divider, Link, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import React from 'react'
import styles from './about.module.scss'
import MapChip from './MapChip'

const CompanyInfoTableMobile: React.FC = () => (
  <>
    <TableContainer>
      <Divider />
      <Table aria-label="simple table">
        <TableBody>
          {/* 社名 */}
          <TableRow className={styles.table_row}>
            <TableCell component="th">
              <Typography fontWeight="bold">社名</Typography>
              <Typography variant="body1">AGキャピタル株式会社 (AG Capital Co.,Ltd)</Typography>
            </TableCell>
          </TableRow>

          {/* 所在地 */}
          <TableRow className={styles.table_row}>
            <TableCell sx={{ verticalAlign: 'baseline', paddingTop: '24px' }} component="th" scope="row">
              <Typography fontWeight="bold">所在地</Typography>
              <Typography pt={0.5} variant="body1">
                〒105-0014
              </Typography>
              <Box display="flex">
                <Typography alignSelf="center" variant="body1">
                  東京都港区芝2-31-19 バンザイビル6F
                </Typography>
                {/* map button */}
                <MapChip />
              </Box>
            </TableCell>
          </TableRow>

          {/* 設立 */}
          <TableRow className={styles.table_row}>
            <TableCell component="th" scope="row">
              <Typography fontWeight="bold">設立</Typography>
              <Typography variant="body1">1985年(昭和60年)12月12日</Typography>
            </TableCell>
          </TableRow>

          {/* 連絡先 */}
          <TableRow className={styles.table_row}>
            <TableCell sx={{ verticalAlign: 'baseline', paddingTop: '24px' }} component="th" scope="row">
              <Typography fontWeight="bold">連絡先</Typography>
              <Typography variant="body1">
                TEL: 03-4503-6400(代表)
                <br />
                FAX: 03(4503)6491
                <br />
                メール: info@agcapital.jp
              </Typography>
            </TableCell>
          </TableRow>

          {/* 資本金 */}
          <TableRow className={styles.table_row}>
            <TableCell sx={{ verticalAlign: 'baseline', paddingTop: '24px' }} component="th" scope="row">
              <Typography fontWeight="bold">資本金</Typography>
              <Typography variant="body1">
                10百万円(授権株式数228千株)
                <br />
                発行済株式数57千株
              </Typography>
            </TableCell>
          </TableRow>

          {/* 株主 */}
          <TableRow className={styles.table_row}>
            <TableCell component="th" scope="row">
              <Typography fontWeight="bold">株主</Typography>
              <Link variant="body1" href="https://www.aiful.co.jp/" target="blank" color="inherit">
                アイフル株式会社
              </Link>
            </TableCell>
          </TableRow>

          {/* 代表取締役 */}
          <TableRow className={styles.table_row}>
            <TableCell component="th" scope="row">
              <Typography fontWeight="bold">代表取締役</Typography>
              <Typography variant="body1">代表取締役社長 山内郁雄</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <Typography variant="body2" p="0px 16px" pt="23px" color="#686868">
      ※ 名刺に記載の連絡先、会社名、事業内容などの情報は、アイフル株式会社及びそのグループ会社と共有します。
    </Typography>
  </>
)

export default CompanyInfoTableMobile
