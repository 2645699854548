import { Box, Typography } from '@mui/material'
import React from 'react'
import { useSize } from '../../../hooks/useSize'
import ArrowButton from '../../Common/ArrowButton'
import ContactButton from '../ContactButton'

type Props = {
  currentPage: number
  totalPage: number
  handleBack: (event: React.MouseEvent) => void
  handleAhead: (event: React.MouseEvent) => void
}

const ListFooter: React.FC<Props> = (props) => {
  const { matchTabletSize } = useSize()
  const { currentPage, totalPage, handleBack, handleAhead } = props
  return (
    <>
      {matchTabletSize ? (
        <Box p={1} bgcolor="#F3F9F9" color="#2557AF" display="flex" alignItems="center" justifyContent="space-between">
          <ArrowButton onClick={handleBack} fill="rgb(0 79 143)" rotate="0deg" />
          <Typography fontSize="18px">{`${currentPage} / ${totalPage}`}</Typography>
          <ArrowButton onClick={handleAhead} fill="rgb(0 79 143)" rotate="180deg" />
        </Box>
      ) : (
        <>
          <Typography fontSize="18px" mt={1} color="white">
            {`${currentPage}/ ${totalPage}`}
          </Typography>
          <ContactButton />
        </>
      )}
    </>
  )
}

export default ListFooter
