import { Box, Typography } from '@mui/material'
import React from 'react'
import TitleAndSubTitle from '../Common/TitleAndSubTitle'
import CompaniesList from './CompaniesList'
import styles from './portfolio.module.scss'

const Portfolio: React.FC = () => (
  <section id="portfolio">
    <div className={styles.container}>
      <TitleAndSubTitle className="white" title="Portfolio" subtitle="投資実績" />

      <Box pb="20px" maxWidth="md" m="auto">
        <Typography m="0px 20px" variant="body1">
          成長性の高い中堅・ベンチャー企業への投資機会を提供することを目的に、AGキャピタルは、1986年に第一号ファンドを設立、現在までに21ファンドを設立し、出資金総額は500億円を超えています。AGキャピタルは、情報・通信分野、流通・サービス分野、環境・ヘルスケア分野を重点投資業種とし、これら分野に関連する中堅・ベンチャー企業へ積極的に投資を行っています。
        </Typography>
      </Box>

      {/* 投資企業先一覧 */}
      <CompaniesList />
    </div>
  </section>
)

export default Portfolio
