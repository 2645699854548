import React from 'react'
import { useSize } from '../../hooks/useSize'
import ContactMobile from './ContactMobile'
import ContactPC from './ContactPC'

const Contact: React.FC = () => {
  const { matchTabletSize } = useSize()
  return <section id="contact">{matchTabletSize ? <ContactMobile /> : <ContactPC />}</section>
}

export default Contact
