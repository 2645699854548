import { Box, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import NewsFlag from '../../assets/png/news_flag.png'
import { useSize } from '../../hooks/useSize'
import { news } from './news'
import styles from './styles.module.scss'

const borderStyle = {
  borderLeft: '5px solid whitesmoke',
  paddingLeft: '15px'
}

const News: React.FC = () => {
  const { matchTabletSize } = useSize()
  // タブレットサイズなら左側に罫線を適用する
  const isBorder = matchTabletSize ? undefined : borderStyle
  const gridTextAlign = matchTabletSize ? 'center' : 'left'
  const displayFlex = !matchTabletSize ? 'flex' : undefined

  return (
    <section id="news">
      <Box className={styles.container}>
        <Grid container className={styles.news_card} maxWidth="md">
          <img className={styles.news_flag} src={NewsFlag} alt="news_flag" />

          <Grid item sm={12} md={1} mb={4} textAlign={gridTextAlign} display="inline-block">
            <div>
              <Typography lineHeight={0.7} display="inline-block" variant="h1" fontSize="2.0em" fontWeight="bold">
                News
              </Typography>
            </div>
            <Typography display="inline-block" fontWeight="bold">
              ニュース
            </Typography>
          </Grid>

          <Grid item sm={12} md={10} width="100%" style={isBorder}>
            {/* タブレットサイズならDividerを表示する */}
            {matchTabletSize && <Divider />}

            {news.map((content) => {
              return (
                <Box
                  key={`news_${content.date}`}
                  className={styles.news_content_list}
                  ml={matchTabletSize ? 0 : '12px'}
                >
                  <Box display={displayFlex}>
                    <Typography className={styles.news_date} variant="body1">
                      {content.date}
                    </Typography>
                    <Typography variant="body1">{content.detail}</Typography>
                  </Box>
                </Box>
              )
            })}
          </Grid>
        </Grid>
      </Box>
    </section>
  )
}

export default News
