import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import React from 'react'
import { useSize } from '../../../hooks/useSize'
import styles from './style.module.scss'

type Props = {
  rotate: '0deg' | '180deg'
  fill: string
  onClick: (event: React.MouseEvent) => void
}

const ArrowButton: React.FC<Props> = (props) => {
  const { rotate, fill, onClick } = props
  const { matchTabletSize } = useSize()

  const arrowStyle = {
    margin: 'auto',
    transform: `rotate(${rotate})`,
    fill: `${fill}`,
    marginTop: '5px'
  }

  return (
    <button
      type="button"
      className={matchTabletSize ? styles.arrow_btn__mobile : styles.arrow_btn__pc}
      onClick={onClick}
    >
      <ArrowBackIosNewRoundedIcon sx={arrowStyle} />
    </button>
  )
}
export default ArrowButton
