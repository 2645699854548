import { ThemeProvider } from '@mui/material'
import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Footer from './components/Footer'
import Header from './components/Header'
import Home from './components/Pages/Home'
import { Maintenance } from './components/Pages/Maintenance'
import { NotFound } from './components/Pages/Page404'
import PrivacyPolicy from './components/Pages/PrivacyPolicy'
import { theme } from './components/theme'

const App: React.FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header />
        <main style={{ height: '100%' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFound />} />
            <Route path="Maintenance" element={<Maintenance />} />
          </Routes>
        </main>
        <Footer />
      </ThemeProvider>
    </>
  )
}
export default App
