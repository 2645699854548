import { Box, Typography } from '@mui/material'
import React from 'react'
import { useSize } from '../../../hooks/useSize'
import styles from './style.module.scss'

const PrivacyPolicy: React.FC = () => {
  const { matchTabletSize } = useSize()

  return (
    <>
      <Box maxWidth="md" className={matchTabletSize ? styles.header__sp : styles.header__pc}>
        <Typography lineHeight={0.9} fontSize={matchTabletSize ? '26px' : '43px'} fontWeight="bold">
          プライバシーポリシー
        </Typography>
        <Typography fontSize={matchTabletSize ? '12px' : '20px'}>privacy policy</Typography>
      </Box>
      <Box maxWidth="md" m="auto">
        <article className={matchTabletSize ? styles.container__sp : styles.container__pc}>
          <Box className={styles.content}>
            <Typography variant="subtitle2" pb={1.5}>
              宣言
            </Typography>
            <Typography variant="body1">
              AGキャピタル株式会社は、当社の「誠実な企業活動を通じて、社会より指示を得る」という経営理念に基づき、社会的責任の重要性を十分に認識して、社会に貢献してゆくこと
              を目指しております。
            </Typography>
          </Box>

          <Box className={styles.content}>
            <Typography variant="subtitle2" pb={1.5}>
              個人情報の管理
            </Typography>
            <Typography variant="body1">
              当社は、個人情報の保護に関する法律（平成15年5月30日法律第57号）その他関 係法令を順守いたします。
              <br />
              また、個人情報保護に関するコンプライアンス・プログラム及び個人情報保護規程を
              定め、継続的に見直し、改善に努めてまいります。
            </Typography>
          </Box>

          <Box className={styles.content}>
            <Typography variant="subtitle2" pb={1.5}>
              個人除法の利用目的
            </Typography>
            <Typography variant="body1">
              当社は、取得した個人情報の取扱いに当たっては、その利用目的を明確にし、その目的の範囲内で利用いたします。
            </Typography>
          </Box>

          <Box className={styles.content}>
            <Typography variant="subtitle2" pb={1.5}>
              個人データの第三者への提供及び共同利用について
            </Typography>
            <Typography variant="body1">
              当社は、グループ会社において名刺交換等でご提供いただいた個人情報を共同利用することがあります。なお、当社は、お客様の同意を得て提供する場合、法令などに基づく場合及びその他正当な
              事由に基づき提供する場合以外には、お客様の個人情報を第三者に提供いたしません。
            </Typography>
          </Box>

          <Box className={styles.content}>
            <Typography variant="subtitle2" pb={1.5}>
              当社の第三者提供先
            </Typography>

            <Box className={styles.content__list}>
              <Typography variant="body1" fontWeight="bold" pb={0.5}>
                1.共同利用者
              </Typography>
              <Typography variant="body1">
                AGキャピタル株式会社、アイフル株式会社、ライフカード株式会社、アイフルギャランティー株式会社、AG債権回収株式会社、アイフルパートナーズ株式会社、アイフルビジネスファイナンス株式会社、その他アイフル株式会社の有価証券報告書記載の子会社および公表している提供先
              </Typography>
            </Box>

            <Box className={styles.content__list}>
              <Typography variant="body1" fontWeight="bold" pb={0.5}>
                2.共同して利用される個人情報の項目
              </Typography>
              <Typography variant="body1">名刺に記載されている個人情報すべて</Typography>
            </Box>

            <Box className={styles.content__list}>
              <Typography variant="body1" fontWeight="bold" pb={0.5}>
                3.協同して利用するものの利用目的
              </Typography>
              <Typography variant="body1">サービス情報などの情報提供</Typography>
            </Box>

            <Box className={styles.content__list}>
              <Typography variant="body1" fontWeight="bold" pb={0.5}>
                4.取得方法
              </Typography>
              <Typography variant="body1">名刺交換など</Typography>
            </Box>

            <Box className={styles.content__list}>
              <Typography variant="body1" fontWeight="bold" pb={0.5}>
                5.共同して利用する者の範囲
              </Typography>
              <Typography variant="body1">当社及び当社のグループ会社</Typography>
            </Box>

            <Box className={styles.content__list}>
              <Typography variant="body1" fontWeight="bold" pb={0.5}>
                6.共同して利用する個人情報の管理について責任を有する者
              </Typography>
              <Typography variant="body1">
                各共同利用者の名称、代表者の氏名、住所については、こちらの
                <a target="blank" rel="noopener" href="https://www.ir-aiful.com/jp/corporate/group.html">
                  グループ一覧のリンク
                </a>
                先を参照下さい。
              </Typography>
            </Box>
          </Box>

          <Box className={styles.content}>
            <Typography variant="subtitle2" pb={1.5}>
              個人情報に関するお問い合わせ
            </Typography>
            <Typography variant="body1">
              個人情報に関するご相談、個人情報の開示、訂正等、利用停止等の手続き等につきましては、次の個
              人情報相談窓口にご相談下さい。
              <br />
              東京都港区芝2丁目31番19号 バンザイビル
              <br />
              AGキャピタル株式会社 投資管理部宛
              <br />
              電話: <a href="tel:03-4503-6400">03-4503-6400</a>
              <br />
              メール: info@agcapital.jp
            </Typography>
          </Box>
        </article>
      </Box>
    </>
  )
}

export default PrivacyPolicy
