import React from 'react'
import { useSize } from '../../hooks/useSize'
import TopMobile from './TopMobile'
import TopPC from './TopPC'

const Top: React.FC = () => {
  const { matchTabletSize } = useSize()
  return matchTabletSize ? <TopMobile /> : <TopPC />
}

export default Top
