import React from 'react'
import { Box, Grid } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import styles from './group.module.scss'
import NewsFlag from '../../assets/png/news_flag.png'
import BusinessPC from '../../assets/png/AGCP_biz_pc.png'
import BusinessMobile from '../../assets/png/AGCP_biz_sp.png'
import Arrow from '../../assets/png/arrow.svg'
import { useSize } from '../../hooks/useSize'

const GroupProduct: React.FC = () => {
  const { matchTabletSize } = useSize()
  const responsive = matchTabletSize ? BusinessMobile : BusinessPC
  const responsiveBreak = matchTabletSize ? '' : <br />
  return (
    <section id="group_product">
      <Box className={styles.container}>
        <Grid container className={styles.group_card} maxWidth="md">
          <a className={styles.group_link} href="https://www.aiful.co.jp/biz/" target="blank" rel="noopener">
            <img className={styles.group_flag} src={NewsFlag} alt="news_flag" />
            <Grid item sm={12} md={3.5}>
              <img className={styles.group_img} src={responsive} alt="AGCP Business" />
            </Grid>
            <Grid item className={styles.group_content} sm={12} md={8.5} width="100%">
              <div className={styles.group_heading_content}>
                <div className={styles.icon_container}>
                  <img className={styles.arrow_right} src={Arrow} alt="arrow" />
                </div>
                <h3 className={styles.group_heading}>グループ商品のご案内</h3>
              </div>
              <p className={styles.group_description}>
                法人のお客様向けに各種グループ商品をご案内しています。
                {responsiveBreak}
                投資以外にも、ビジネスカードなどの決済サービスや、事業者ローンなどの資金調達、
                {responsiveBreak}
                その他さまざまな商品をご用意しています。
              </p>
            </Grid>
          </a>
        </Grid>
      </Box>
    </section>
  )
}

export default GroupProduct
