import { Box, Typography } from '@mui/material'
import React from 'react'
import './style.scss'

type Props = {
  title: string
  subtitle: string
  className: 'white' | 'gradient'
}

const TitleAndSubTitle: React.FC<Props> = (props) => {
  const { title, subtitle, className } = props
  return (
    <Box textAlign="center" pt="60px" pb="30px">
      <div>
        <Typography className={className} lineHeight={0.9} fontWeight="bold" fontSize="3em">
          {title}
        </Typography>
      </div>
      <Typography className={className} fontWeight="bold">
        {subtitle}
      </Typography>
    </Box>
  )
}

export default TitleAndSubTitle
