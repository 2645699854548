import { Box, Typography } from '@mui/material'
import React from 'react'
import BGImageSP from '../../assets/png/contact_sp.png'
import { ReactComponent as ArrowIcon } from '../../assets/svg/arrow_btn_white.svg'
import TitleAndSubTitle from '../Common/TitleAndSubTitle'
import styles from './contact.module.scss'
import MailerButton from './MailerButton'

const ContactMobile: React.FC = () => (
  <Box
    textAlign="center"
    width="100%"
    sx={{ backgroundImage: `url(${BGImageSP})`, height: '100%', padding: '0px 0px 60px 0px' }}
  >
    <TitleAndSubTitle title="Contact" subtitle="お問合せ" className="white" />

    <Typography variant="body2" p="0px 55px" color="white">
      投資や資金調達のご相談はこちらからお問い合わせ下さい。
    </Typography>

    <Box p="16px 50px">
      <a className={styles.tel_btn} href="tel:03-4503-6400">
        <Box borderTop="1px solid #505050" display="flex" alignItems="center" justifyContent="space-between">
          <Typography pt={1} pb={1} className={styles.content} fontSize="26px" fontWeight="bold" color="white">
            <span>Tel</span>03-4503-6400
          </Typography>
          <ArrowIcon style={{ fill: '#fff', height: '14px', width: 'auto' }} />
        </Box>
      </a>

      <Box borderTop="1px solid #505050" borderBottom="1px solid #505050">
        <Typography
          pt={1}
          pb={1}
          className={styles.content}
          fontSize="26px"
          fontWeight="bold"
          alignItems="center"
          color="white"
        >
          <span>Fax</span>03-4503-6491
        </Typography>
      </Box>
    </Box>
    <Box pt={2}>
      <MailerButton />
    </Box>
  </Box>
)
export default ContactMobile
