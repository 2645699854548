import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { ReactComponent as ArrowIcon } from '../../assets/svg/arrow_btn_blue.svg'
import TitleAndSubTitle from '../Common/TitleAndSubTitle'
import Images from './Images'
import Paragraph from './Paragraph'
import styles from './support.module.scss'

const Support: React.FC = () => (
  <section id="support">
    <TitleAndSubTitle className="gradient" title="Support" subtitle="サポート" />
    <Grid container direction="row-reverse">
      <Grid item sm={12} md={6.5}>
        <Images />
      </Grid>
      <Grid item sm={12} md={5.5} mt={2}>
        <Paragraph />
      </Grid>
    </Grid>

    <Box textAlign="center" pb="80px">
      <AnchorLink href="#contact">
        <button type="button" className={styles.support_contact_btn}>
          <span>
            <div />
            <Typography pl="8px" pt={0.5} fontWeight="bold" fontSize="15px">
              お問合せ
            </Typography>
            <ArrowIcon style={{ height: '14px', width: 'auto', fill: 'white' }} className="arrow_icon" />
          </span>
        </button>
      </AnchorLink>
    </Box>
  </section>
)

export default Support
