import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import BGImagePC from '../../assets/png/contact_pc.png'
import styles from './contact.module.scss'
import MailerButton from './MailerButton'

const ContactPC: React.FC = () => (
  <Box
    p="145px"
    width="100%"
    sx={{
      backgroundImage: `url(${BGImagePC})`,
      backgroundSize: 'cover'
    }}
  >
    <Grid container justifyContent="center" color="white">
      <Grid pr="60px" item>
        <Typography variant="h1" fontSize="2em" fontWeight="bold">
          Contact
        </Typography>
        <Typography variant="subtitle1" fontWeight="bold">
          お問合せ
        </Typography>
      </Grid>
      <Grid item>
        <Box borderLeft="4px solid white" pl="60px">
          <Typography variant="body1">投資や資金調達のご相談はこちらからお問合せ下さい。</Typography>
          <Box pt={1} pb={1} display="flex" alignItems="center">
            <Box>
              <Typography className={styles.content} fontSize="26px" fontWeight="bold">
                <span>Tel</span>03-4503-6400
              </Typography>
              <Typography className={styles.content} fontSize="26px" fontWeight="bold" alignItems="center">
                <span>Fax</span>03-4503-6491
              </Typography>
            </Box>
            <Box pl={2}>
              <MailerButton />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  </Box>
)

export default ContactPC
