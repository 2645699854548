import React from 'react'

export const Maintenance: React.FC = () => {
  return (
    <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
      <p style={{ alignSelf: 'center', fontWeight: 'bold' }}>
        ただいまシステムメンテナンス中です。しばらく時間を置いてから再度アクセスしてください。
      </p>
    </div>
  )
}
