import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { ReactComponent as ArrowIcon } from '../../assets/svg/arrow_btn_blue.svg'
import styles from './portfolio.module.scss'

const ContactButton: React.FC = () => (
  <AnchorLink href="#contact">
    <button style={{ marginTop: '80px', marginBottom: '100px' }} type="button" className={styles.contact_btn}>
      <span>
        <div />
        <p>お問合せ</p>
        <ArrowIcon className={styles.arrow_icon} />
      </span>
    </button>
  </AnchorLink>
)

export default ContactButton
