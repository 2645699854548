import { Box, Typography } from '@mui/material'
import React from 'react'
import topImageMobile from '../../assets/png/topImageMobile.png'
import styles from './top.module.scss'

const TopMobile: React.FC = () => (
  <section id="top">
    <img src={topImageMobile} alt="top-catch" />

    <Box p={1} pb={5}>
      <Box pr={1} pl={1} mt={-5} display="inline-block">
        <p className={styles.company_catch_phrase__sp}>
          <span>独自性</span>と<span>革新性</span>で
        </p>
        <p className={styles.company_catch_phrase__sp}>
          <span>企業価値</span>を<span>創造</span>する
        </p>
      </Box>

      <Box p={1}>
        <Typography fontWeight="bold" variant="body1" lineHeight={1.5}>
          AGキャピタルは“独自性”と“革新性”を有し、IPOを目指す中堅・ベンチャー企業のビジネス・パートナーとして企業価値創造に取り組んでいます。
        </Typography>
      </Box>
    </Box>
  </section>
)

export default TopMobile
