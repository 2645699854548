import { Box, Divider, Drawer, List } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { ReactComponent as CloseIcon } from '../../assets/svg/icon_menu_close.svg'
import { ReactComponent as OpenIcon } from '../../assets/svg/icon_menu_open.svg'
import CustomHashLink from '../Common/CustomHashLink'
import styles from './header.module.scss'
import { MENU_ITEMS } from './MENU_ITEMS'

const MenuBarMobile: React.FC = () => {
  // ドロワーの状態管理
  const [isOpen, setIsOpen] = useState<boolean>(false)
  // ドロワーの開閉
  const toggleDrawer = (): void => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <button className={styles.toggle_btn__open} type="button" onClick={toggleDrawer} aria-label="menu">
        <OpenIcon style={{ height: '15px' }} />
      </button>

      <Drawer
        sx={{ backgroundColor: '#004fff9c' }} // ドロワー背景色
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer}
        variant="temporary"
      >
        <Box textAlign="right">
          <button className={styles.toggle_btn__close} type="button" aria-label="close_button" onClick={toggleDrawer}>
            <CloseIcon style={{ height: '20px' }} />
          </button>
        </Box>
        <List className={styles.drawer_menu_list}>
          <Divider />
          <Box className={styles.menu_box__res}>
            <a href="/" className={styles.menu_item__res}>
              ホーム
            </a>
          </Box>
          <Divider />
          {MENU_ITEMS.map((item) => (
            <div key={item.id}>
              <div className={styles.menu_box__res}>
                <CustomHashLink
                  id={item.id}
                  name={item.name}
                  className={styles.menu_item__res}
                  onClick={toggleDrawer}
                />
              </div>
              <Divider />
            </div>
          ))}
        </List>

        <Box textAlign="center" mt={5}>
          <HashLink smooth onClick={toggleDrawer} className={styles.contact_button__res} to="/#contact">
            お問合せ
          </HashLink>
        </Box>

        <Box textAlign="center" mt={4}>
          <Link onClick={toggleDrawer} to="PrivacyPolicy" className={styles.privacy_policy}>
            プライバシーポリシー
          </Link>
        </Box>
      </Drawer>
    </>
  )
}

export default MenuBarMobile
