import React from 'react'
import { useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

type Props = {
  id: string
  name: string
  className: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

const CustomHashLink: React.FC<Props> = (props) => {
  // pathnameを取得
  const { pathname } = useLocation()
  const { id, name, className, onClick } = props
  const isNotHome = pathname !== '/' ? `/#${id}` : `#${id}`

  // スクロールの高さ調整
  // 参照:https://github.com/rafgraph/react-router-hash-link/issues/25
  const scrollWidthOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
    const yOffset = -50
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
  }

  return (
    <HashLink smooth className={className} to={isNotHome} onClick={onClick} scroll={(el) => scrollWidthOffset(el)}>
      {name}
    </HashLink>
  )
}

// オプショナルPropsのデフォルト値を指定
CustomHashLink.defaultProps = { onClick: undefined }

export default CustomHashLink
