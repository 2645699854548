import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import styles from './about.module.scss'

const MapChip: React.FC = () => (
  <AnchorLink className={styles.map_chip} offset="20" href="#access">
    Map
  </AnchorLink>
)
export default MapChip
